export default {
    data() {
        return {
            feature: [{
                title: '“数据+模型”的顶层设计',
                numberPic: require("../../assets/icons/num1.png"),
                text: '数据模型可以将复杂的教师研修数据抽象成清晰明了的结构，更好地展现数据之间的关系，使数据更加易于理解和准确。教师可以清晰的看到我要干什么，工作目标是什么，通过静态数据采集和动态数据跟踪，对教师研修进行管理，助力主管部门对教师进行综合评价。'
            }, {
                title: '动态化自定义模型，让平台更聪明',
                numberPic: require("../../assets/icons/num2.png"),
                text: '平台可以根据学校管理情况自由搭建多级类目模型，每个指标可以自定义可视化搭建表单。'
            }, {
                title: '平台多端联动，结构化管理',
                numberPic: require("../../assets/icons/num3.png"),
                text: '教师可以通过网页端、数字基座、微信端、登陆教师研修系统，开展课例评价、课程学习、研讨交流、作业提交、问卷调研等研修活动，并通过标签化的方式，对研修活动和资源进行结构化的管理，便于伴生性资源和成果的再调取、学习和展示。'
            }, {
                title: '智能生成教师研修发展报告',
                numberPic: require("../../assets/icons/num4.png"),
                text: '平台聚焦教师课堂教学、教科研活动、教学反思、个人发展、教师考核、档案管理等多场景应用，通过设置不同的模型指标参数，智能化比对指标和教师的目标达成情况，汇聚统计数据至个人学期考核，智能生成教师研修与发展个人报告。'
            }],
        }
    },
    created() {

    },
}