<template>
  <div class="sshx">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>教师研修与发展平台</h1>
          <p><a href="/">首页</a>
            <Icon class="arrow" type="ios-arrow-forward" />我们的优势
            <Icon class="arrow" type="ios-arrow-forward" />教师支持与发展系统
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
              <div class="inner-column" style="padding: 0">
                <div class="image">
                  <a href="#"><img src="../../assets/resource/sshx.png" alt="" /></a>
                </div>
              </div>
              </Col>

              <Col :xl="12" :lg="12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
                  </div>
                  <h2>教师支持与发展系统</h2>
                </div>
                <div class="text">
                  <p>
                    教师研修与发展支持系统基于“数据+模型”的顶层设计，从教师档案建设、教师综合记录、教师阶段成长考核三个维度出发，提供智能化模型，根据学校不同的管理特点，打造自己的专属模型，聚焦教师专业能力发展，覆盖教师教研、科研、培训等多场景应用，通过静态数据采集和动态数据跟踪，对教师研修进行管理，助力主管部门对教师进行综合评价，最终汇聚成教师研修与发展个人报告，构建个人研修与发展画像。
                  </p>
                  <!-- <p>平台将一系列业务活动抽象为不同类型的“流程节点”，通过流程编辑器进行连接，可实现业务流程触发、流转、审批、消息推送等操作，
                    免去了代码编写工作，让使用者能够完全专注于校园业务场景，为学校提供智慧灵活的办公OA搭建工具。</p> -->
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col :xl="8" :lg="8" :md="12" :sm="24" v-for="(item, index) in feature" :key="index">
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <h3>{{ item.title }}</h3>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
          </Col>
        </Row>

        <div class="sec-title">
          <div class="title-icon">
            <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
          </div>
          <h2>图集</h2>
        </div>
        <Carousel v-model="value" loop autoplay dots="none" arrow="always" autoplay-speed="5000">
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zcfz2.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zcfz1.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zcfz3.png" alt="" srcset="">
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import yxfz from "./yxfz.js";
export default yxfz;
</script>

<style lang="less" scoped>
@import "./yxfz.less";
</style>